/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import algoliasearch from 'algoliasearch/lite';
import Cookies from 'js-cookie';

import { CartContext } from './CartContext';

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

// select algolia index
const dealerInformationIndex = client.initIndex('dealers');
const DealerSearchContext = createContext();

function DealerSearchProvider({ children }) {
  const cartContext = useContext(CartContext);
  const [dealerSearchActive, setDealerSearchActive] = useState(false);
  const [dealerGateModalActive, setDealerGateModalActive] = useState(false);
  const [dealerSearchResults, setDealerSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [storedDealer, setStoredDealer] = useState(null);

  const fetchStoredDealerData = async () => {
    const dealerId = Cookies.get('dealerId');
    try {
      // fetch dealer data from Algolia
      const response = await dealerInformationIndex.search(dealerId, {
        restrictSearchableAttributes: ['id'],
      });

      if (response.hits.length) {
        setStoredDealer(response.hits[0]);
        setDealerGateModalActive(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // does cookie have store dealerId? Populate storedDealer
    // with fresh dealer data from Algolia
    if (typeof window !== 'undefined' && Cookies.get('dealerId')) {
      (async () => {
        await fetchStoredDealerData();
      })();
    }
  }, []);

  useEffect(() => {
    // if firstLoad is true, delete dealerSearchResults
    if (firstLoad) {
      setDealerSearchResults([]);
    }
  }, [firstLoad]);

  useEffect(() => {
    const updateDealerInCart = async () => {
      if (cartContext?.cart?.id && storedDealer?.id) {
        const attributes = [
          {
            key: 'Dealer ID',
            value: storedDealer?.id?.toString() || '',
          },
        ];

        await cartContext?.updateCartAttributes(attributes);
      }
    };
    updateDealerInCart();
  }, [storedDealer, cartContext?.cart?.id]);

  const getDealerSearchResults = async zipcode => {
    setFirstLoad(false);
    setLoading(true); // spinner hook
    try {
      const response = await fetch(
        `${process.env.GATSBY_POSTCODE_SEARCH_API}?postalCode=${zipcode}`,
        {
          method: 'GET',
        }
      );

      setDealerSearchResults(await response.json());

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const toggleDealerSearch = () => {
    setDealerSearchActive(!dealerSearchActive);
  };

  const deleteDealerSearchResults = () => {
    setDealerSearchResults([]);
  };

  const value = useMemo(
    () => ({
      dealerSearchActive,
      dealerSearchResults,
      setDealerSearchActive,
      toggleDealerSearch,
      getDealerSearchResults,
      deleteDealerSearchResults,
      firstLoad,
      setFirstLoad,
      loading,
      storedDealer,
      fetchStoredDealerData,
      setDealerSearchResults,
      setDealerGateModalActive,
      dealerGateModalActive,
    }),
    [
      dealerSearchResults,
      dealerSearchActive,
      storedDealer,
      setFirstLoad,
      fetchStoredDealerData,
    ]
  );

  return (
    <DealerSearchContext.Provider value={value}>
      {children}
    </DealerSearchContext.Provider>
  );
}

DealerSearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DealerSearchProvider, DealerSearchContext };
