import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  initRecharge,
  loginWithShopifyStorefront,
  listSubscriptions,
  getSubscription,
  updateAddress,
  cancelSubscription,
  updateSubscription,
  productSearch,
  listPaymentMethods,
  listAddresses,
  sendCustomerNotification,
  listCharges,
  skipCharge,
} from '@rechargeapps/storefront-client';

import { LocaleContext } from './LocaleContext';
import { CustomerContext } from './CustomerContext';

const RechargeSubscriptionsContext = createContext();

function RechargeSubscriptionsProvider({
  children,
  subscriptionsMenuLabel = 'Subscriptions',
}) {
  const localeContext = useContext(LocaleContext);
  const { checkCognitoIdExpiry } = useContext(CustomerContext);
  const [session, setSession] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [internalSubscriptions, setInternalSubscriptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [retentionStrategies, setRetentionStrategies] = useState([
    {
      reason: 'I no longer have my pet',
    },
    {
      reason: 'I do not need the tracking capabilities',
    },
    {
      reason: 'I switched to a different containment solution',
    },
    {
      reason: 'The product did not work as I expected',
    },
    {
      reason: 'The subscription is too expensive',
    },
    {
      reason: 'I want a different billing frequency',
    },
    {
      reason: 'Other',
    },
  ]);

  const [autoshipCancelReasons, setAutoshipCancelReasons] = useState([
    {
      reason: 'This is too expensive',
    },
    {
      reason: 'This was created by accident',
    },
    {
      reason: 'I already have more than I need',
    },
    {
      reason: 'I need it sooner',
    },
    {
      reason: 'I no longer use this product',
    },
    {
      reason: 'I want a different product or variety',
    },
    {
      reason: 'Other',
    },
  ]);

  const [userHasLicense, setUserHasLicense] = useState(false);

  const isBrowser = typeof window !== 'undefined';

  const currency = localeContext?.currency || 'USD';

  const accessTokenKey =
    currency === 'CAD' ? 'customer_access_token_cad' : 'customer_access_token';

  // Function to update Recharge/Shopify session
  const getRechargeSession = async () => {
    if (isBrowser) {
      const customerAccessToken = window.localStorage.getItem(accessTokenKey);

      if (customerAccessToken) {
        try {
          const rechargeSession = await loginWithShopifyStorefront(
            process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN,
            customerAccessToken
          );

          if (
            rechargeSession?.message === 'Customer does not exist in Recharge.'
          ) {
            setSession(null);
            return null;
          }

          setSession(rechargeSession);
          return rechargeSession;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(`Error trying to get Recharge session:`, err);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(
          `Error trying to get Recharge session, missing customer access token.`
        );
      }
    }
    return null;
  };

  // Initialize Recharge SDK
  const initializeRecharge = async () => {
    initRecharge({
      storeIdentifier: `${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com`,
      storefrontAccessToken: process.env.GATSBY_RECHARGE_API_KEY,
      loginRetryFn: await getRechargeSession,
    });
  };

  // Get All Subscriptions
  const getRechargeSubscriptions = async (
    userSession,
    limit = 250,
    cursor = '',
    customParams = {}
  ) => {
    const params = {
      limit,
      cursor,
      ...customParams,
    };

    if (!cursor) {
      params.sort_by = 'id-desc';
    }

    let response = await listSubscriptions(userSession, params);
    let responseSubscriptions = response?.subscriptions || [];

    const fetchSubscriptions = async nextCursor => {
      if (nextCursor) {
        params.cursor = nextCursor;
        response = await listSubscriptions(userSession, params);
        responseSubscriptions = responseSubscriptions.concat(
          response?.subscriptions || []
        );

        if (response?.next_cursor) {
          await fetchSubscriptions(response?.next_cursor);
        }
      }
    };

    await fetchSubscriptions(response?.next_cursor);
    setSubscriptions(responseSubscriptions || []);
  };

  // Get Subscription
  const getRechargeSubscription = async (
    userSession,
    subscriptionId,
    options
  ) => {
    if (userSession) {
      const subscription = await getSubscription(
        userSession,
        subscriptionId,
        options
      );
      return subscription;
    }
    return null;
  };

  const updateRechargeAddress = async (
    userSession,
    addressId,
    updateRequest
  ) => {
    if (userSession) {
      const updatedSubscription = await updateAddress(
        userSession,
        addressId,
        updateRequest
      );
      return updatedSubscription;
    }
    return null;
  };

  // Cancel Subscription
  const cancelRechargeSubscription = async (
    userSession,
    subscriptionId,
    cancelReason,
    extraComments,
    sendEmail = true
  ) => {
    if (userSession) {
      const canceledSubscription = await cancelSubscription(
        userSession,
        subscriptionId,
        {
          cancellation_reason: cancelReason,
          cancellation_reason_comments: extraComments,
          send_email: sendEmail,
        }
      );
      return canceledSubscription;
    }
    return null;
  };

  // Update Subscription
  const updateRechargeSubscription = async (
    userSession,
    subscriptionId,
    updateRequest
  ) => {
    if (userSession) {
      const updatedSubscription = await updateSubscription(
        userSession,
        subscriptionId,
        updateRequest
      );
      return updatedSubscription;
    }
    return null;
  };

  // Skip Subscription
  const skipRechargeSubscription = async (
    userSession,
    subscriptionId,
    nextChargeDate
  ) => {
    if (userSession) {
      const { charges } = await listCharges(session);

      const nextCharge = charges.find(ch =>
        ch.line_items.some(
          li =>
            li.purchase_item_id === subscriptionId &&
            ch.scheduled_at === nextChargeDate
        )
      );

      await skipCharge(session, nextCharge.id, [subscriptionId]);
      await getRechargeSubscriptions(session);
    }
    return null;
  };

  // Get all subscription products from recharge
  const getSubscriptionProducts = async userSession => {
    if (userSession) {
      const subscriptionProducts = await productSearch(userSession, {
        limit: 250,
        format_version: '2022-06',
        product_published_status: 'published',
        has_plans: true,
      });

      setProducts(subscriptionProducts?.products || []);
    }
  };

  // Get all recharge addresses
  const getSubscriptionAddresses = async userSession => {
    if (userSession) {
      const response = await listAddresses(userSession, {
        limit: 250,
      });
      setAddresses(response?.addresses || []);
    }
  };

  // Get all payment methods
  const getPaymentMethods = async userSession => {
    if (userSession) {
      const response = await listPaymentMethods(userSession, {
        limit: 250,
        include: ['addresses'],
      });
      setPaymentMethods(response?.payment_methods || []);
    }
  };

  const getInternalSubscriptions = async () => {
    await checkCognitoIdExpiry();
    const cognitoToken = localStorage.getItem('cognito_id_token');
    if (cognitoToken) {
      try {
        const RSC_CONSUMER_API = `${process.env.GATSBY_RSC_API}/consumerapi`;
        const response = await fetch(`${RSC_CONSUMER_API}/subscriptions`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cognitoToken}`,
          },
          method: 'GET',
        });

        if (!response.ok) {
          // eslint-disable-next-line no-console
          console.error(
            'There was a problem with fetching subscriptions from RSC Consumer API:',
            response.statusText
          );
        } else {
          const data = await response.json();
          if (data) {
            setInternalSubscriptions(data);

            setUserHasLicense(
              data?.some(
                sub =>
                  sub.serviceLicenses.length > 0 &&
                  sub.serviceLicenses?.some(license =>
                    ['consumed', 'allocated', 'available'].includes(
                      license?.status
                    )
                  )
              )
            );
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `Trying to call getInternalSubscriptions without Cognito Token.`
      );
    }
  };

  const getServiceLicensesForSubscription = subscriptionId =>
    internalSubscriptions?.find(
      internalSub => internalSub?.rechargeId === subscriptionId?.toString()
    )?.serviceLicenses;

  const sendPaymentMethodUpdateNotification = async (
    userSession,
    addressId,
    paymentMethodId
  ) => {
    if (userSession) {
      const response = await sendCustomerNotification(
        session,
        'SHOPIFY_UPDATE_PAYMENT_INFO',
        {
          address_id: addressId,
          payment_method_id: paymentMethodId,
        }
      );
      return response;
    }

    return null;
  };

  const memoizedValue = useMemo(
    () => ({
      initializeRecharge,
      getRechargeSession,
      session,
      subscriptions,
      internalSubscriptions,
      addresses,
      paymentMethods,
      products,
      getRechargeSubscriptions,
      getRechargeSubscription,
      updateRechargeAddress,
      cancelRechargeSubscription,
      updateRechargeSubscription,
      skipRechargeSubscription,
      getSubscriptionProducts,
      getSubscriptionAddresses,
      getPaymentMethods,
      subscriptionsMenuLabel,
      getInternalSubscriptions,
      autoshipCancelReasons,
      retentionStrategies,
      setRetentionStrategies,
      setAutoshipCancelReasons,
      getServiceLicensesForSubscription,
      sendPaymentMethodUpdateNotification,
      userHasLicense,
    }),
    [
      session,
      subscriptions,
      internalSubscriptions,
      addresses,
      paymentMethods,
      products,
      userHasLicense,
    ]
  );

  return (
    <RechargeSubscriptionsContext.Provider value={memoizedValue}>
      {children}
    </RechargeSubscriptionsContext.Provider>
  );
}

RechargeSubscriptionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  subscriptionsMenuLabel: PropTypes.string,
};

export { RechargeSubscriptionsProvider, RechargeSubscriptionsContext };
